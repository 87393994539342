import { useEffect, useState } from 'react';
import { useControllers } from '../../../../../common/helpers/react/hooks/useControllers';
import { tAsString } from '../../../../../common/helpers/react/text/tAsString';
import LoadingProgress from '../../../common/placeholders/loading/LoadingProgress';
import RolesTable from './RolesTable';
import { RolesToolbar } from './RolesToolbar';
import RolesAddRolesSearch from "./RolesAddRolesSearch";

export default function RolesSearchPresenter() {
    const [checkedRows, setCheckedRows] = useState<string[]>([]);
    const [removeRolesMode, setRemoveRolesMode] = useState(false);
    const [editRolesMode, setEditRolesMode] = useState(false);
    const [mainTableView, setMainTableView] = useState(true);
    const roles = useControllers().roles;
    const authInfo = useControllers().auth.getAuthInfo$().useState();    
    const canEdit = authInfo?.pccsStatus?.adminType === 'SA'
    const isBusy = ['GETTING_PARENT_ROLES', 'UPDATING_PARENT_ROLES'].includes(roles.isBusy$().useState() as string);

     useEffect(() => {
         setCheckedRows([]);
     }, [removeRolesMode]);

    return (<>
        <h1>{tAsString('MANAGE_ROLES')}</h1>
        {isBusy && removeRolesMode && <LoadingProgress/>}
        <RolesAddRolesSearch mainTableView={mainTableView}
                             setMainTableView={setMainTableView}/>
        <RolesToolbar removeRolesMode={removeRolesMode}
                      setRemoveRolesMode={setRemoveRolesMode}
                      checkedRows={checkedRows}
                      mainTableView={mainTableView}
                      setMainTableView={setMainTableView}
                      editRolesMode={editRolesMode}
                      setEditRolesMode={setEditRolesMode}
                      canEdit={canEdit}/>
        <RolesTable checkedRows={checkedRows}
                    setCheckedRows={setCheckedRows}
                    removeRolesMode={removeRolesMode}
                    mainTableView={mainTableView}
                    setMainTableView={setMainTableView}
                    editRolesMode={editRolesMode}
                    canEdit={canEdit}/>
    </>);
}