import {Controller, useForm} from 'react-hook-form';
import {Panel, TextInput} from '@sabre/spark-react-core';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import React from 'react';
import {useParams} from 'react-router-dom';
import {useI18Next} from '../../../../../common/helpers/react/hooks/useI18Next';
import ActionPanel from '../../../common/components/actionPanel/ActionPanel';
import {ButtonConfig} from '../../../../../controllers/dialog/ButtonConfig';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {useSubtitle} from '../../../../../common/helpers/react/hooks/useSubtitle';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';
import {asNumber} from '../../../../../common/helpers/converters/asNumber';
import {isDefined} from '../../../../../common/types/guards/isDefined';
import DefaultTableInput from '../../../common/components/table/inputs/DefaultTableInput';
import styles from './CreateOrUpdateConfiguration.module.css';

interface ConfigurationFormValues {
    redappName: string;
    configurationName: string;
    value: string;
    description: string;
}

enum ActionType {
    UPDATE = 'UpdateConfiguration',
    CREATE = 'CreateConfiguration'
}

const Settings = {
    [ActionType.UPDATE]: {
        subtitle: 'EDIT_CONFIGURATION_HEADER',
        header: 'EDIT_CONFIGURATION_HEADER',
        successMessage: 'THE_X_CONFIGURATION_UPDATE_SUCCESS',
        errorMessage: 'THE_X_CONFIGURATION_UPDATE_FAILED'
    },
    [ActionType.CREATE]: {
        subtitle: 'CREATE_CONFIGURATION_TITLE',
        header: 'NEW_CONFIGURATION',
        successMessage: 'THE_X_CONFIGURATION_CREATION_SUCCESS',
        errorMessage: 'THE_X_CONFIGURATION_CREATION_FAILED'
    }
};

const getYupValidationSchema = (actionType: ActionType) => {
    return yup.object({
        redappName: yup
            .string()
            .required(tAsString('PROVIDE_A_FEATURE_NAME')),
        configurationName: yup
            .string()
            .required(tAsString('PROVIDE_A_CONFIGURATION_NAME'))
            .matches(/^[a-zA-Z0-9_]{1,32}$/, tAsString('ONLY_ALPHANUMERICS_AND_UNDERSCORES')),
        value: yup
            .string()
            .required(tAsString('PROVIDE_A_VALUE')),
        description: yup
            .string()
            .required(tAsString('PROVIDE_A_DESCRIPTION'))
    });
};

export const CreateOrUpdateConfiguration = () => {
    const {t} = useI18Next();
    const {id} = useParams();

    const controllers = useControllers();
    const configurations = controllers.configurations;
    const redApps = controllers.redApps;
    const dialog = controllers.dialog;
    const http = controllers.http;

    const configurationId = asNumber(id);
    const configuration = configurations.getConfigurationById$(configurationId).useState();
    const actionType = isDefined(configuration) ? ActionType.UPDATE : ActionType.CREATE;
    const {subtitle, header, successMessage, errorMessage} = Settings[actionType];
    useSubtitle(tAsString(subtitle));
    const options = [
        {
            label: '-- Select --',
            value: ''
        }
    ];
    options.push(...(redApps.getRedApps$().useState() || []).map(redApp => ({
        value: redApp.iu_name,
        label: redApp.name
    })));

    const {
        control,
        formState: {errors},
        getValues,
        handleSubmit
    } = useForm<ConfigurationFormValues>({
        mode: 'all',
        resolver: yupResolver(getYupValidationSchema(actionType))
    });

    const onSubmit = async () => {
        await dialog.takeNotifiedAction({
            action: async () => {
                if (actionType === ActionType.UPDATE) {
                    await configurations.updateConfiguration(configurationId, getValues('configurationName'), getValues('value'), getValues('description'));
                } else {
                    await configurations.createConfiguration(getValues('redappName'), getValues('configurationName'), getValues('value'), getValues('description'));
                }
                await http.navigateTo('/configurations');
            },
            successMessage: t(successMessage, {
                name: getValues('configurationName')
            }),
            errorMessage: t(errorMessage, {
                name: getValues('configurationName')
            }),
            finalizer: async () => {
                await configurations.refreshConfigurations();
            }
        });
    };

    const onClose = async () => {
        await http.navigateTo('/configurations');
    };

    const actionButtons: ButtonConfig[] = [
        {
            className: cssClasses(
                'qa-cancel-button',
                'aat-autokey-escape'
            ),
            onClick: onClose,
            children: t('CANCEL'),
            secondary: true
        },
        {
            className: 'qa-save-and-close-button',
            children: t('SAVE_&_CLOSE'),
            onClick: handleSubmit(onSubmit)
        }
    ];

    return (
        <>
            <h1>{t(header)}</h1>
            <Panel>
                <form>
                    <div className={'row'} style={{justifyContent: 'center'}}>
                        <div className={'col-lg-6'}>
                            <Controller
                                name={'redappName'}
                                control={control}
                                defaultValue={configuration?.redapp_name}
                                render={({field}) =>
                                    <DefaultTableInput
                                        type={actionType === ActionType.CREATE ? 'select' : 'text'}
                                        name={field.name}
                                        value={actionType === ActionType.CREATE ? field.value : options.find(it => it.value === field.value)?.label ?? field.value}
                                        defaultValue={'-'} // default value has to be different from empty string due to https://ptjira.sabre.com/browse/UXSPR-42
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        options={options}
                                        status={errors[field.name] ? MessageStatus.ERROR : undefined}
                                        statusMessage={errors[field.name]?.message}
                                        label={t('FEATURE_NAME')}
                                        className={'qa-configuration-redapp-name-input'}
                                        disabled={actionType === ActionType.UPDATE}
                                    />
                                }
                            />
                        </div>
                        <div className={'col-lg-6 spark-pad-t-1--lte-md'}>
                            <Controller
                                name={'configurationName'}
                                control={control}
                                defaultValue={configuration?.configuration_name}
                                render={({field}) =>
                                    <TextInput
                                        name={field.name}
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        status={errors[field.name] ? MessageStatus.ERROR : undefined}
                                        statusMessage={errors[field.name]?.message}
                                        label={t('CONFIGURATION_NAME')}
                                        className={'qa-configuration-name-input'}
                                        maxLength={32}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className={'row'} style={{justifyContent: 'center'}}>
                        <div className={'col-lg-12 spark-pad-t-1'}>
                            <Controller
                                name={'description'}
                                control={control}
                                defaultValue={configuration?.description}
                                render={({field}) =>
                                    <TextInput
                                        name={field.name}
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        status={errors[field.name] ? MessageStatus.ERROR : undefined}
                                        statusMessage={errors[field.name]?.message}
                                        label={t('DESCRIPTION')}
                                        className={'qa-configuration-description-input'}
                                        maxLength={1000}
                                    />
                                }
                            />
                        </div>
                        <div className={'col-lg-12 spark-pad-t-1'}>
                            <Controller
                                name={'value'}
                                control={control}
                                defaultValue={configuration?.value}
                                render={({field}) =>
                                    <TextInput
                                        name={field.name}
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        status={errors[field.name] ? MessageStatus.ERROR : undefined}
                                        statusMessage={errors[field.name]?.message}
                                        label={t('VALUE')}
                                        className={cssClasses(
                                            'qa-configuration-value-input',
                                            styles.TextArea
                                        )}
                                        multiLine={true}
                                        maxLength={1048576}
                                    />
                                }
                            />
                        </div>
                    </div>
                </form>
            </Panel>
            <ActionPanel buttons={actionButtons}/>
        </>
    );
};