import {Accordion, Button, Drawer} from '@sabre/spark-react-core';
import DefaultListTable from '../../../../../common/components/table/DefaultListTable';
import {default as React} from 'react';
import {ColumnDescription} from '../../../../../common/components/table/ColumnDescription';
import {useControllers} from '../../../../../../../common/helpers/react/hooks/useControllers';
import {useI18Next} from '../../../../../../../common/helpers/react/hooks/useI18Next';
import styles from './GroupWizardSettingsAccordion.module.css';
import DefaultToggle, {DefaultToggleProps} from '../../../../../common/components/toggle/DefaultToggle';
import {ProductType} from '../../../../../../../common/model/types/products/ProductType';
import {cssClasses} from '../../../../../../../common/helpers/browser/cssClasses';
import {useHashWizard} from '../../../../../common/components/wizards/useHashWizard';
import {isProductState} from '../../../../../../../common/model/types/products/isProductState';
import {ProductEnablement, RedAppPlacementType} from '../../../../../../../core/rest-client/aat';
import {RxGroupProduct} from '../../../../../../../common/model/db/types/RxGroupProduct';
import {isPropertyState} from '../../../../../../../common/model/types/properties/isPropertyState';
import {RxGroupProperty} from '../../../../../../../common/model/db/types/RxGroupProperty';
import {ensure} from '../../../../../../../common/types/guards/ensure';
import {isRecordOf} from '../../../../../../../common/types/guards/isRecordOf';
import DefaultTableInput from '../../../../../common/components/table/inputs/DefaultTableInput';
import {isDefined} from '../../../../../../../common/types/guards/isDefined';
import {tAsString} from '../../../../../../../common/helpers/react/text/tAsString';
import {asInt} from '../../../../../../../common/helpers/converters/asInt';
import ChangeWorkflowOrder from './change-order/ChangeWorkflowOrder';
import {getAllowUserOverride} from './misc/getAllowUserOverride';
import {getPropertyInputCellHelpers} from './misc/getPropertyInputCellHelpers';
import {isWorkflowProperty} from './misc/isWorkflowProperty';
import GroupWizardShowFeatureAssigneesModal from './GroupWizzardShowProductAssigneesModal';
import {isArrayOf} from '../../../../../../../common/types/guards/isArrayOf';
import {isString} from '../../../../../../../common/types/guards/isString';
import {useParams} from 'react-router-dom';
import {GroupWizardSteps} from '../GroupWizardSteps';
import {getRedAppPlacementInputCellHelpers} from './misc/getRedAppPlacementInputCellHelpers';
import {getRedAppConfigurationInputCellHelpers} from './misc/getRedAppConfigurationInputCellHelpers';

export default function GroupWizardSettingsAccordion() {
    const {t} = useI18Next();
    const {id} = useParams();

    const groups = useControllers().groups;

    const searchResults = groups.getConfigurationSearchResults$().useState();

    const workspaceTable = useWorkspaceTable(searchResults?.workspaces, id);
    const workflowTable = useWorkflowTable(searchResults?.workflows, id);
    const redAppsTable = useRedAppsTable(searchResults?.redApps, id);
    const propertiesTable = usePropertiesTable(searchResults?.properties);

    return (
        // <Drawer> must be inside <Accordion> in same file
        // otherwise error occurs
        <div className={cssClasses(
            styles.Accordion,
            `qa-accordion`
        )}>
            <Accordion>
                <Drawer headingLevel={'h2'} title={t('RED_APPS')} expanded={true}>
                    {redAppsTable}
                </Drawer>
                <Drawer headingLevel={'h2'} title={t('WORKFLOWS')} expanded={true}>
                    <ChangeWorkflowOrder properties={searchResults?.properties}/>
                    {workflowTable}
                </Drawer>
                <Drawer headingLevel={'h2'} title={t('WORKSPACE')} expanded={true}>
                    {workspaceTable}
                </Drawer>
                <Drawer headingLevel={'h2'} title={t('USER_OVERRIDE_PROPERTIES')} expanded={true}>
                    {propertiesTable}
                </Drawer>
            </Accordion>
        </div>
    );
}

function usePropertiesTable(groupProperties?: RxGroupProperty[]) {
    const {t} = useI18Next();
    const wh = useHashWizard();

    const data = groupProperties
        ?.filter(it => !isWorkflowProperty(it));

    const columns: ColumnDescription<RxGroupProperty>[] = [
        {
            header: t('FEATURE'),
            size: 3,
            render: row => {
                return (
                    <>
                        <div className={styles.GroupName}>
                            {row?.property_ref?.name}
                        </div>
                        <div className={cssClasses(
                            'spark-hidden--gte-md',
                            styles.ResponsiveDescription
                        )}>{row.property_ref?.description}</div>
                    </>
                );
            }
        },
        {
            header: t('OVERRIDE_VALUE'),
            size: 2,
            render: row => {
                const {
                    propertyId,
                    initialValue,
                    onChange,
                    onBlurCapture
                } = getPropertyInputCellHelpers(row, wh);

                const entries = Object.entries(row.possible_values ?? {});

                if (entries.length) {
                    let options = entries
                        .map(pair => {
                            return {
                                label: (pair[1]),
                                value: pair[0]
                            };
                        });

                    const selectOption = [{
                        label: '-- Select --',
                        value: ''
                    }];

                    options = selectOption.concat(options);

                    return (
                        <DefaultTableInput
                            type={'select'}
                            name={`override_value::${propertyId}`}
                            onChange={onChange}
                            options={options}
                            defaultValue={initialValue ?? ``}
                            ariaLabel={t('OVERRIDE_VALUE_OF_X', {
                                name: row.property_ref?.name
                            })}
                        />
                    );
                } else {
                    return (
                        <DefaultTableInput
                            type={'text'}
                            name={`override_value::${propertyId}`}
                            onBlurCapture={onBlurCapture}
                            defaultValue={initialValue ?? ``}
                            label={initialValue?.length ? `` : `-- Enter value --`}
                            ariaLabel={t('OVERRIDE_VALUE_OF_X', {
                                name: row.property_ref?.name
                            })}
                        />
                    );
                }
            }
        },
        {
            renderHeader: col => {
                const splitted = tAsString('ALLOW_OVERRIDE').split(' ');
                return <>{splitted.slice(0, 2).join(' ')}<br/>{splitted.slice(2).join(' ')}</>;
            },
            size: 2,
            align: 'center',
            render: row => {
                const properties = wh.stepData?.properties ?? {};
                ensure(isRecordOf(isPropertyState), properties);

                const propertyId = row.property_ref?.id;

                const onChange: DefaultToggleProps['onChange'] = async value => {
                    if (isDefined(propertyId)) {
                        await wh.setStepMember(['properties', propertyId], {
                            id: propertyId,
                            overrideValue: properties[propertyId]?.overrideValue
                                ?? row.override_value
                                ?? ``,
                            allowUserOverride: value
                        });
                    }
                };

                const initialValue = getAllowUserOverride(row, wh);

                return <DefaultToggle
                    name={`allow_override::${propertyId}`}
                    initialValue={initialValue ? 'true' : 'false'}
                    onChange={onChange}
                    options={[
                        {
                            value: 'false',
                            label: t('DENY'),
                            color: 'red'
                        },
                        {
                            value: 'true',
                            label: t('ALLOW'),
                            color: 'green'
                        }
                    ]}
                    ariaLabel={t('ALLOW_OR_DENY_OVERRIDE_VALUE_OF_X', {
                        name: row?.property_ref?.name
                    })}
                />;
            }
        },
        {
            header: t('DESCRIPTION'),
            size: 5,
            render: row => <>{row.property_ref?.description}</>,
            hiddenWhen: 'lte-sm'
        }
    ];

    return <DefaultListTable
        columns={columns}
        data={data}
    />;
}

function useWorkspaceTable(groupToGroupSettings?: RxGroupProduct[], groupId?: string) {
    const filteredSettings = groupToGroupSettings
        ?.filter(it => it.product_ref?.type === ProductType.WORKSPACE);

    return useListTable(filteredSettings, groupId, ProductType.WORKSPACE);
}

function useWorkflowTable(groupToGroupSettings?: RxGroupProduct[], groupId?: string) {
    const filteredSettings = groupToGroupSettings
        ?.filter(it => it.product_ref?.type === ProductType.WORKFLOW);

    return useListTable(filteredSettings, groupId);
}

function useRedAppsTable(groupToGroupSettings?: RxGroupProduct[], groupId?: string) {
    const filteredSettings = groupToGroupSettings
        ?.filter(it => it.product_ref?.type === ProductType.RED_APP);

    return useListTable(filteredSettings, groupId, ProductType.RED_APP);
}

function useListTable(data?: RxGroupProduct[], groupId?: string, type?: ProductType) {
    const {t} = useI18Next();
    const wh = useHashWizard();

    const controllers = useControllers();
    const dialogController = controllers.dialog;
    const redAppsController = controllers.redApps;
    const redApps = redAppsController.getRedApps$().useState() ?? [];

    const optionalPlacementColumn: ColumnDescription<RxGroupProduct>[] = type === ProductType.RED_APP
        ? [
            {
                header: t('REDAPP_PLACEMENT'),
                size: 2,
                render: row => {
                    const {
                        productId,
                        initialValue,
                        onChange
                    } = getRedAppPlacementInputCellHelpers(row, wh);

                    const selectOption = [
                        {
                            label: 'Applications panel',
                            value: RedAppPlacementType.AppsPanel
                        },
                        {
                            label: 'Side bar',
                            value: RedAppPlacementType.Sidebar
                        }
                    ];

                    return (<DefaultTableInput
                        type={'select'}
                        name={`override_location_value::${productId}`}
                        onChange={onChange}
                        options={selectOption}
                        defaultValue={initialValue ?? ``}
                        ariaLabel={t('OVERRIDE_PLACEMENT_OF_X', {
                            name: row.product_ref?.name
                        })}
                    />);
                }
            }
        ] : [];

    const optionalConfigurationColumn: ColumnDescription<RxGroupProduct>[] = (type === ProductType.WORKSPACE || type === ProductType.RED_APP)
        ? [
           {
                header: t('CONFIGURATION'),
                size: 2,
                render: row => {
                    const {
                        productId,
                        initialValue,
                        onChange
                    } = getRedAppConfigurationInputCellHelpers(row, wh);

                    const redAppIuName = redApps.find(it => it.name === row.product_ref?.name)?.iu_name ?? row.product_ref?.name;
                    const configurations = controllers.configurations
                        .getConfigurations$()
                        .useState()
                        ?.filter(it => it.redapp_name === redAppIuName) ?? [];
                    if (configurations.length) {
                        const selectOption = [
                            {
                                label: '-- Select --',
                                value: ''
                            }
                        ];
                        const options = selectOption.concat(configurations.map(it => ({
                            value: it.configuration_name,
                            label: it.configuration_name
                        })));

                        return <DefaultTableInput
                            type={'select'}
                            name={`override_configuration_value::${productId}`}
                            onChange={onChange}
                            options={options}
                            defaultValue={initialValue ?? ``}
                            ariaLabel={t('OVERRIDE_CONFIGURATION_OF_X', {
                                name: row.product_ref?.name
                            })}
                        />;
                    }
                    return <span className={styles.Placeholder}
                                 aria-label={t('NOT_AVAILABLE')}>- {t('N/A')} -</span>;
                }
            }
        ] : [];

    const columns: ColumnDescription<RxGroupProduct>[] = [
        {
            header: t('FEATURE'),
            size: 3,
            render: row => <div className={styles.GroupName}>{row.product_ref?.name}</div>
        },
        {
            header: t('SETTINGS'),
            size: 2,
            render: row => {
                const products = wh.stepData?.products ?? {};
                ensure(isRecordOf(isProductState), products);

                const remoteId = asInt(row.product_ref?.id);

                const onChange: DefaultToggleProps['onChange'] = value => {
                    if (!isNaN(remoteId)) {
                        wh.setStepMember(['products', remoteId], {
                            id: remoteId,
                            type: row.product_ref?.type,
                            productEnablement: value,
                            redAppPlacement: products[remoteId]?.redAppPlacement ?? row.redapp_placement,
                            redAppConfiguration: products[remoteId]?.redAppConfiguration ?? row.redAppConfiguration
                        }).andWeAreDone();
                    }
                };

                const name = `product::${remoteId}`;
                const stepValue = products[remoteId];

                const initialValue = isProductState(stepValue)
                    ? stepValue.productEnablement
                    : row.product_enablement;

                return (
                    <>
                        <DefaultToggle
                            className={styles.Toggle}
                            onChange={onChange}
                            name={name}
                            initialValue={initialValue}
                            ariaLabel={t('ASSIGNMENT_SWITCH_OF_X', {
                                name: row.product_ref?.name
                            })}
                            options={[
                                {
                                    value: ProductEnablement.On,
                                    color: 'green'
                                },
                                {
                                    value: ProductEnablement.Default
                                },
                                {
                                    value: ProductEnablement.Off,
                                    color: 'red'
                                }
                            ]}
                        />
                    </>
                );
            }
        },
        ...optionalPlacementColumn,
        ...optionalConfigurationColumn,
        {
            header: t('COUNT'),
            size: 2,
            render: row => {
                const agentsInfo = t('X_OF_Y_ASSIGNEES', {
                    using: row.product_ref?.using_agents ?? 0,
                    count: row.product_ref?.total_agents ?? 0
                });

                const allData = wh.allStepsData$.useState();

                const toAdd = allData?.data?.[GroupWizardSteps.AGENTS]?.add ?? [];
                ensure(isArrayOf(isString), toAdd);

                const toRemove = allData?.data?.[GroupWizardSteps.AGENTS]?.remove ?? [];
                ensure(isArrayOf(isString), toRemove);

                return (
                    <>
                        <Button
                            textOnly
                            className={styles.AgentsInfo}
                            onClick={(e): void => {
                                dialogController.showModal({
                                    maximized: false,
                                    title: row.product_ref?.name,
                                    className: 'qa-modal-assignees',
                                    children:
                                        [<GroupWizardShowFeatureAssigneesModal
                                            productId={asInt(row.product_ref?.id)}
                                            groupId={asInt(groupId)}
                                            assigneesCount={asInt(row.product_ref?.using_agents)}
                                            toAdd={toAdd}
                                            toRemove={toRemove}
                                        />
                                        ],
                                    buttons: [{
                                        children: tAsString('CANCEL'),
                                        className: cssClasses(
                                            'qa-modal-cancel-button',
                                            'aat-autokey-escape-first'
                                        ),
                                        onClick: () => {
                                            dialogController.closeModal().andWeAreDone();
                                        }
                                    }]
                                });
                            }}>
                            {agentsInfo}
                        </Button>
                    </>);
            }
        },
        {
            header: t('DESCRIPTION'),
            size: 3,
            render: row => <>{row.product_ref?.description}</>
        }
    ];

    return <DefaultListTable
        columns={columns}
        data={data}
    />;
}