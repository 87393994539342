/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConfigurableProduct } from './ConfigurableProduct';
import {
    ConfigurableProductFromJSON,
    ConfigurableProductFromJSONTyped,
    ConfigurableProductToJSON,
} from './ConfigurableProduct';
import type { EnableProduct } from './EnableProduct';
import {
    EnableProductFromJSON,
    EnableProductFromJSONTyped,
    EnableProductToJSON,
} from './EnableProduct';
import type { ProductEnablement } from './ProductEnablement';
import {
    ProductEnablementFromJSON,
    ProductEnablementFromJSONTyped,
    ProductEnablementToJSON,
} from './ProductEnablement';
import type { RedAppPlacementType } from './RedAppPlacementType';
import {
    RedAppPlacementTypeFromJSON,
    RedAppPlacementTypeFromJSONTyped,
    RedAppPlacementTypeToJSON,
} from './RedAppPlacementType';

/**
 * 
 * @export
 * @interface RedAppPlacementSetting
 */
export interface RedAppPlacementSetting extends ConfigurableProduct {
    /**
     * 
     * @type {RedAppPlacementType}
     * @memberof RedAppPlacementSetting
     */
    redAppPlacement: RedAppPlacementType;
    /**
     * 
     * @type {ProductEnablement}
     * @memberof RedAppPlacementSetting
     */
    productEnablement: ProductEnablement;
}

/**
 * Check if a given object implements the RedAppPlacementSetting interface.
 */
export function instanceOfRedAppPlacementSetting(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "redAppPlacement" in value;
    isInstance = isInstance && "productEnablement" in value;

    return isInstance;
}

export function RedAppPlacementSettingFromJSON(json: any): RedAppPlacementSetting {
    return RedAppPlacementSettingFromJSONTyped(json, false);
}

export function RedAppPlacementSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedAppPlacementSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...ConfigurableProductFromJSONTyped(json, ignoreDiscriminator),
        'redAppPlacement': RedAppPlacementTypeFromJSON(json['redAppPlacement']),
        'productEnablement': ProductEnablementFromJSON(json['productEnablement']),
    };
}

export function RedAppPlacementSettingToJSON(value?: RedAppPlacementSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...ConfigurableProductToJSON(value),
        'redAppPlacement': RedAppPlacementTypeToJSON(value.redAppPlacement),
        'productEnablement': ProductEnablementToJSON(value.productEnablement),
    };
}

