/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseGroupRequest } from './BaseGroupRequest';
import {
    BaseGroupRequestFromJSON,
    BaseGroupRequestFromJSONTyped,
    BaseGroupRequestToJSON,
} from './BaseGroupRequest';
import type { PartialGroup } from './PartialGroup';
import {
    PartialGroupFromJSON,
    PartialGroupFromJSONTyped,
    PartialGroupToJSON,
} from './PartialGroup';
import type { PropertyUpdate } from './PropertyUpdate';
import {
    PropertyUpdateFromJSON,
    PropertyUpdateFromJSONTyped,
    PropertyUpdateToJSON,
} from './PropertyUpdate';
import type { RedAppPlacementSetting } from './RedAppPlacementSetting';
import {
    RedAppPlacementSettingFromJSON,
    RedAppPlacementSettingFromJSONTyped,
    RedAppPlacementSettingToJSON,
} from './RedAppPlacementSetting';
import type { WorkflowEnablement } from './WorkflowEnablement';
import {
    WorkflowEnablementFromJSON,
    WorkflowEnablementFromJSONTyped,
    WorkflowEnablementToJSON,
} from './WorkflowEnablement';
import type { WorkspaceEnablement } from './WorkspaceEnablement';
import {
    WorkspaceEnablementFromJSON,
    WorkspaceEnablementFromJSONTyped,
    WorkspaceEnablementToJSON,
} from './WorkspaceEnablement';

/**
 * 
 * @export
 * @interface GroupRequest
 */
export interface GroupRequest extends BaseGroupRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof GroupRequest
     */
    cmGroupIds?: Array<number>;
}

/**
 * Check if a given object implements the GroupRequest interface.
 */
export function instanceOfGroupRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GroupRequestFromJSON(json: any): GroupRequest {
    return GroupRequestFromJSONTyped(json, false);
}

export function GroupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...BaseGroupRequestFromJSONTyped(json, ignoreDiscriminator),
        'cmGroupIds': !exists(json, 'cmGroupIds') ? undefined : json['cmGroupIds'],
    };
}

export function GroupRequestToJSON(value?: GroupRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...BaseGroupRequestToJSON(value),
        'cmGroupIds': value.cmGroupIds,
    };
}

