/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorizationStatus,
  Status,
  UpdateSitesRequest,
} from '../models';
import {
    AuthorizationStatusFromJSON,
    AuthorizationStatusToJSON,
    StatusFromJSON,
    StatusToJSON,
    UpdateSitesRequestFromJSON,
    UpdateSitesRequestToJSON,
} from '../models';

export interface CreateUpdateSiteRequest {
    id: number;
    updateSitesRequest: UpdateSitesRequest;
    authorization?: string;
    onBehalfOf?: string;
}

/**
 * 
 */
export class UpdateSitesApi extends runtime.BaseAPI {

    /**
     * Create update site.
     */
    async createUpdateSiteRaw(requestParameters: CreateUpdateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Status>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createUpdateSite.');
        }

        if (requestParameters.updateSitesRequest === null || requestParameters.updateSitesRequest === undefined) {
            throw new runtime.RequiredError('updateSitesRequest','Required parameter requestParameters.updateSitesRequest was null or undefined when calling createUpdateSite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/updatesites`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSitesRequestToJSON(requestParameters.updateSitesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusFromJSON(jsonValue));
    }

    /**
     * Create update site.
     */
    async createUpdateSite(requestParameters: CreateUpdateSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Status> {
        const response = await this.createUpdateSiteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
