/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Group } from './Group';
import {
    GroupFromJSON,
    GroupFromJSONTyped,
    GroupToJSON,
} from './Group';
import type { PropertyUpdate } from './PropertyUpdate';
import {
    PropertyUpdateFromJSON,
    PropertyUpdateFromJSONTyped,
    PropertyUpdateToJSON,
} from './PropertyUpdate';
import type { RedAppPlacementSetting } from './RedAppPlacementSetting';
import {
    RedAppPlacementSettingFromJSON,
    RedAppPlacementSettingFromJSONTyped,
    RedAppPlacementSettingToJSON,
} from './RedAppPlacementSetting';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';
import type { StatusCodes } from './StatusCodes';
import {
    StatusCodesFromJSON,
    StatusCodesFromJSONTyped,
    StatusCodesToJSON,
} from './StatusCodes';
import type { WorkflowEnablement } from './WorkflowEnablement';
import {
    WorkflowEnablementFromJSON,
    WorkflowEnablementFromJSONTyped,
    WorkflowEnablementToJSON,
} from './WorkflowEnablement';
import type { WorkspaceEnablement } from './WorkspaceEnablement';
import {
    WorkspaceEnablementFromJSON,
    WorkspaceEnablementFromJSONTyped,
    WorkspaceEnablementToJSON,
} from './WorkspaceEnablement';

/**
 * 
 * @export
 * @interface GroupResponse
 */
export interface GroupResponse extends Status {
    /**
     * 
     * @type {Group}
     * @memberof GroupResponse
     */
    group: Group;
    /**
     * 
     * @type {Array<WorkspaceEnablement>}
     * @memberof GroupResponse
     */
    workspace?: Array<WorkspaceEnablement>;
    /**
     * 
     * @type {Array<PropertyUpdate>}
     * @memberof GroupResponse
     */
    properties?: Array<PropertyUpdate>;
    /**
     * 
     * @type {Array<WorkflowEnablement>}
     * @memberof GroupResponse
     */
    workflows?: Array<WorkflowEnablement>;
    /**
     * 
     * @type {Array<RedAppPlacementSetting>}
     * @memberof GroupResponse
     */
    redapps?: Array<RedAppPlacementSetting>;
}

/**
 * Check if a given object implements the GroupResponse interface.
 */
export function instanceOfGroupResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "group" in value;

    return isInstance;
}

export function GroupResponseFromJSON(json: any): GroupResponse {
    return GroupResponseFromJSONTyped(json, false);
}

export function GroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...StatusFromJSONTyped(json, ignoreDiscriminator),
        'group': GroupFromJSON(json['group']),
        'workspace': !exists(json, 'workspace') ? undefined : ((json['workspace'] as Array<any>).map(WorkspaceEnablementFromJSON)),
        'properties': !exists(json, 'properties') ? undefined : ((json['properties'] as Array<any>).map(PropertyUpdateFromJSON)),
        'workflows': !exists(json, 'workflows') ? undefined : ((json['workflows'] as Array<any>).map(WorkflowEnablementFromJSON)),
        'redapps': !exists(json, 'redapps') ? undefined : ((json['redapps'] as Array<any>).map(RedAppPlacementSettingFromJSON)),
    };
}

export function GroupResponseToJSON(value?: GroupResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...StatusToJSON(value),
        'group': GroupToJSON(value.group),
        'workspace': value.workspace === undefined ? undefined : ((value.workspace as Array<any>).map(WorkspaceEnablementToJSON)),
        'properties': value.properties === undefined ? undefined : ((value.properties as Array<any>).map(PropertyUpdateToJSON)),
        'workflows': value.workflows === undefined ? undefined : ((value.workflows as Array<any>).map(WorkflowEnablementToJSON)),
        'redapps': value.redapps === undefined ? undefined : ((value.redapps as Array<any>).map(RedAppPlacementSettingToJSON)),
    };
}

