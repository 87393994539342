/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PartialGroup } from './PartialGroup';
import {
    PartialGroupFromJSON,
    PartialGroupFromJSONTyped,
    PartialGroupToJSON,
} from './PartialGroup';
import type { PropertyUpdate } from './PropertyUpdate';
import {
    PropertyUpdateFromJSON,
    PropertyUpdateFromJSONTyped,
    PropertyUpdateToJSON,
} from './PropertyUpdate';
import type { RedAppPlacementSetting } from './RedAppPlacementSetting';
import {
    RedAppPlacementSettingFromJSON,
    RedAppPlacementSettingFromJSONTyped,
    RedAppPlacementSettingToJSON,
} from './RedAppPlacementSetting';
import type { WorkflowEnablement } from './WorkflowEnablement';
import {
    WorkflowEnablementFromJSON,
    WorkflowEnablementFromJSONTyped,
    WorkflowEnablementToJSON,
} from './WorkflowEnablement';
import type { WorkspaceEnablement } from './WorkspaceEnablement';
import {
    WorkspaceEnablementFromJSON,
    WorkspaceEnablementFromJSONTyped,
    WorkspaceEnablementToJSON,
} from './WorkspaceEnablement';

import {
     GroupRequestFromJSONTyped,
     UpdateGroupRequestFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface BaseGroupRequest
 */
export interface BaseGroupRequest {
    /**
     * 
     * @type {PartialGroup}
     * @memberof BaseGroupRequest
     */
    group: PartialGroup;
    /**
     * 
     * @type {Array<WorkspaceEnablement>}
     * @memberof BaseGroupRequest
     */
    workspace?: Array<WorkspaceEnablement>;
    /**
     * 
     * @type {Array<RedAppPlacementSetting>}
     * @memberof BaseGroupRequest
     */
    redapps?: Array<RedAppPlacementSetting>;
    /**
     * 
     * @type {Array<WorkflowEnablement>}
     * @memberof BaseGroupRequest
     */
    workflows?: Array<WorkflowEnablement>;
    /**
     * 
     * @type {Array<PropertyUpdate>}
     * @memberof BaseGroupRequest
     */
    properties?: Array<PropertyUpdate>;
}

/**
 * Check if a given object implements the BaseGroupRequest interface.
 */
export function instanceOfBaseGroupRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "group" in value;

    return isInstance;
}

export function BaseGroupRequestFromJSON(json: any): BaseGroupRequest {
    return BaseGroupRequestFromJSONTyped(json, false);
}

export function BaseGroupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['openApiType'] === 'GroupRequest') {
            return GroupRequestFromJSONTyped(json, true);
        }
        if (json['openApiType'] === 'UpdateGroupRequest') {
            return UpdateGroupRequestFromJSONTyped(json, true);
        }
    }
    return {
        
        'group': PartialGroupFromJSON(json['group']),
        'workspace': !exists(json, 'workspace') ? undefined : ((json['workspace'] as Array<any>).map(WorkspaceEnablementFromJSON)),
        'redapps': !exists(json, 'redapps') ? undefined : ((json['redapps'] as Array<any>).map(RedAppPlacementSettingFromJSON)),
        'workflows': !exists(json, 'workflows') ? undefined : ((json['workflows'] as Array<any>).map(WorkflowEnablementFromJSON)),
        'properties': !exists(json, 'properties') ? undefined : ((json['properties'] as Array<any>).map(PropertyUpdateFromJSON)),
    };
}

export function BaseGroupRequestToJSON(value?: BaseGroupRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group': PartialGroupToJSON(value.group),
        'workspace': value.workspace === undefined ? undefined : ((value.workspace as Array<any>).map(WorkspaceEnablementToJSON)),
        'redapps': value.redapps === undefined ? undefined : ((value.redapps as Array<any>).map(RedAppPlacementSettingToJSON)),
        'workflows': value.workflows === undefined ? undefined : ((value.workflows as Array<any>).map(WorkflowEnablementToJSON)),
        'properties': value.properties === undefined ? undefined : ((value.properties as Array<any>).map(PropertyUpdateToJSON)),
    };
}

