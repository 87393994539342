import styles from './RightMenu.module.css';
import DotSeparator from '../../common/components/separators/DotSeparator';
import VerticalSeparator from '../../common/components/separators/VerticalSeparator';
import {Button} from '@sabre/spark-react-core';
import {KEY} from '../../../../common/helpers/id/KEY';
import React from 'react';
import DevMode from '../../common/restrict/DevMode';
import LogoutButton from './LogoutButton';

export type RightMenuProps = {
    setLanguage: (lang: string) => void;
    currentLanguage: string;
}

export default function RightMenu(props: RightMenuProps) {
    const languageLinks = getLanguageLinks(['en'], props);

    return (
        <div className={styles.RightMenu}>
            <DevMode>
                {languageLinks}
                <VerticalSeparator/>
            </DevMode>
            <LogoutButton/>
        </div>
    );
}

function getLanguageLinks(langs: string[], props: RightMenuProps) {
    return langs
        .map((lang, idx) => {
            const text = <Button
                disabled={lang === props.currentLanguage}
                textOnly={true}
                secondary={true}
                onClick={() => props.setLanguage(lang)}
            >{lang}</Button>;

            const separator = idx === langs.length - 1
                ? <></>
                : <DotSeparator/>;

            return <React.Fragment key={KEY(`${lang}${idx}`)}>{text}{separator}</React.Fragment>;
        });
}