/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Property } from './Property';
import {
    PropertyFromJSON,
    PropertyFromJSONTyped,
    PropertyToJSON,
} from './Property';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';
import type { StatusCodes } from './StatusCodes';
import {
    StatusCodesFromJSON,
    StatusCodesFromJSONTyped,
    StatusCodesToJSON,
} from './StatusCodes';
import type { Workspace } from './Workspace';
import {
    WorkspaceFromJSON,
    WorkspaceFromJSONTyped,
    WorkspaceToJSON,
} from './Workspace';

/**
 * 
 * @export
 * @interface GroupSettingsResponse
 */
export interface GroupSettingsResponse extends Status {
    /**
     * 
     * @type {Array<Workspace>}
     * @memberof GroupSettingsResponse
     */
    workspace?: Array<Workspace>;
    /**
     * 
     * @type {Array<Workspace>}
     * @memberof GroupSettingsResponse
     */
    redApps?: Array<Workspace>;
    /**
     * 
     * @type {Array<Property>}
     * @memberof GroupSettingsResponse
     */
    properties?: Array<Property>;
}

/**
 * Check if a given object implements the GroupSettingsResponse interface.
 */
export function instanceOfGroupSettingsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GroupSettingsResponseFromJSON(json: any): GroupSettingsResponse {
    return GroupSettingsResponseFromJSONTyped(json, false);
}

export function GroupSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupSettingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...StatusFromJSONTyped(json, ignoreDiscriminator),
        'workspace': !exists(json, 'workspace') ? undefined : ((json['workspace'] as Array<any>).map(WorkspaceFromJSON)),
        'redApps': !exists(json, 'redApps') ? undefined : ((json['redApps'] as Array<any>).map(WorkspaceFromJSON)),
        'properties': !exists(json, 'properties') ? undefined : ((json['properties'] as Array<any>).map(PropertyFromJSON)),
    };
}

export function GroupSettingsResponseToJSON(value?: GroupSettingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...StatusToJSON(value),
        'workspace': value.workspace === undefined ? undefined : ((value.workspace as Array<any>).map(WorkspaceToJSON)),
        'redApps': value.redApps === undefined ? undefined : ((value.redApps as Array<any>).map(WorkspaceToJSON)),
        'properties': value.properties === undefined ? undefined : ((value.properties as Array<any>).map(PropertyToJSON)),
    };
}

