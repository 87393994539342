/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
     RedAppPlacementSettingFromJSONTyped,
     WorkspaceEnablementFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface ConfigurableProduct
 */
export interface ConfigurableProduct {
    /**
     * 
     * @type {number}
     * @memberof ConfigurableProduct
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof ConfigurableProduct
     */
    redAppConfiguration?: string;
}

/**
 * Check if a given object implements the ConfigurableProduct interface.
 */
export function instanceOfConfigurableProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;

    return isInstance;
}

export function ConfigurableProductFromJSON(json: any): ConfigurableProduct {
    return ConfigurableProductFromJSONTyped(json, false);
}

export function ConfigurableProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurableProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['openApiType'] === 'RedAppPlacementSetting') {
            return RedAppPlacementSettingFromJSONTyped(json, true);
        }
        if (json['openApiType'] === 'WorkspaceEnablement') {
            return WorkspaceEnablementFromJSONTyped(json, true);
        }
    }
    return {
        
        'productId': json['productId'],
        'redAppConfiguration': !exists(json, 'redAppConfiguration') ? undefined : json['redAppConfiguration'],
    };
}

export function ConfigurableProductToJSON(value?: ConfigurableProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'redAppConfiguration': value.redAppConfiguration,
    };
}

