/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EnableProduct } from './EnableProduct';
import {
    EnableProductFromJSON,
    EnableProductFromJSONTyped,
    EnableProductToJSON,
} from './EnableProduct';
import type { ProductEnablement } from './ProductEnablement';
import {
    ProductEnablementFromJSON,
    ProductEnablementFromJSONTyped,
    ProductEnablementToJSON,
} from './ProductEnablement';

/**
 * 
 * @export
 * @interface WorkflowEnablement
 */
export interface WorkflowEnablement {
    /**
     * 
     * @type {number}
     * @memberof WorkflowEnablement
     */
    productId: number;
    /**
     * 
     * @type {ProductEnablement}
     * @memberof WorkflowEnablement
     */
    productEnablement: ProductEnablement;
}

/**
 * Check if a given object implements the WorkflowEnablement interface.
 */
export function instanceOfWorkflowEnablement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "productEnablement" in value;

    return isInstance;
}

export function WorkflowEnablementFromJSON(json: any): WorkflowEnablement {
    return WorkflowEnablementFromJSONTyped(json, false);
}

export function WorkflowEnablementFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowEnablement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'productEnablement': ProductEnablementFromJSON(json['productEnablement']),
    };
}

export function WorkflowEnablementToJSON(value?: WorkflowEnablement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'productEnablement': ProductEnablementToJSON(value.productEnablement),
    };
}

